import React, { useEffect } from "react";
import CircleLink from "./circle-link";
import utils from "../components/utils";
import { scroll } from "../theme";
import { DEFAULT_INNER_COMPONENT_DELAY_MS } from "../constants";

export default function ContactForm({ data }) {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTrigger.create({
        trigger: "#contact",
        start: "top top+=100px",
        end: "bottom top+=100px",
        scroller: scroll.container,
        onEnter() {
          utils.fadeNav(true)
        },
        onEnterBack() {
          utils.fadeNav(true)
          document.body.classList.remove('is-bottom')
          if (utils.isDesktop()) {
            document.querySelector('#nav').classList.add('nav-hidden')
          }
        },
        onLeave() {
          utils.fadeNav(false)
          document.body.classList.add('is-bottom')
          if (utils.isDesktop()) {
            document.querySelector('#nav').classList.remove('nav-hidden')
          }

        },
        onLeaveBack() {
          utils.fadeNav(false)
        }
      })
    }, DEFAULT_INNER_COMPONENT_DELAY_MS)
  }, [])

  return (
    <section id="contact" className="bg-dark text-sand min-h-screen flex" >
      <div className="px-4 lg:px-0 self-stretch flex flex-col justify-between px-0 lg:px-12 p-12">
        <h2 className="font-sailec text-lg max-w-xl lg:mt-12 mb-12">We are committed to supporting you. <br className="hidden lg:inline" />Make a confidential enquiry here.</h2>
        <form name="Enquiry"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/thankyou"
          className="max-w-lg">
          <input type="hidden" name="form-name" value="Enquiry" />
          <div className="grid grid-cols-2 gap-4">
            <input required className="col-span-1" type="text" name="firstName" placeholder="First Name" />
            <input required className="col-span-1" type="text" name="lastName" placeholder="Last Name" />
          </div>
          <input required type="email" name="email" placeholder="Email" />
          <input required type="text" name="phone" placeholder="Phone" />
          <select required name="service" placeholder="Service Required">
            <option value="" disabled selected>Service Required</option>
            <option value="Separation & Divorce">Separation & Divorce</option>
            <option value="Parenting arrangements">Parenting arrangements</option>
            <option value="Property settlement<">Property settlement</option>
            <option value="spousal-maintenance">Spousal maintenance</option>
            <option value="Child support">Child support</option>
            <option value="Adoption & surrogacy">Adoption & surrogacy</option>
            <option value="Domestic violence<">Domestic violence</option>
            <option value="Pre-nuptial agreements">Pre-nuptial agreements</option>
            <option value="Court representation">Court representation</option>
            <option value="Second opinion">Second opinion</option>
            <option value="Other">Other</option>
          </select>
          <select required name="service" placeholder="How would you like to be contacted?">
            <option value="" disabled selected>How would you like to be contacted?</option>
            <option value="Email">Email</option>
            <option value="Phone">Phone</option>
          </select>
          <CircleLink inverse={true} isSubmit={true} title="Submit" />
        </form>
      </div>
    </section>
  )
}

export const query = graphql`
  {
    header: file(relativePath: {eq: "about-header-image.jpg"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
