import React, { Component, useEffect } from "react";
import Layout from '../components/layout'
import { window } from 'browser-monads'
import { graphql } from "gatsby"
import ContactForm from '../components/contact-form'
import { gsap } from 'gsap'
import { DEFAULT_INNER_COMPONENT_DELAY_MS } from "../constants";
import utils from "../components/utils";


export default function NotFoundPage({ data }) {
  useEffect(() => {
    utils.resetPage()

    setTimeout(() => {
      window.locomotiveScroll.start()
      gsap.to('.services-header h1', {
        opacity: 1,
        duration: 0.5,
        ease: "power2.out"
      })
      gsap.to('#nav', {
        opacity: 1,
        duration: 0.7,
        ease: "power2.out"
      })
    }, DEFAULT_INNER_COMPONENT_DELAY_MS)
  }, [])

  return (
    <Layout>
      <section id="about" className="px-4 lg:px-0 min-h-screen flex items-center">
        <div className="max-w-2xl mx-auto">
          <h2 className="font-sailec lg:text-lg text-center">
            Sorry, we couldn't find that page.
            </h2>
        </div>
      </section>

      <ContactForm />
    </Layout>
  )
}

export const query = graphql`
  {
    header: file(relativePath: {eq: "about-header-image.jpg"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
